import React, { Component } from 'react';
import {
  Router,
  Route,
  Switch,
  __RouterContext as RouterContext,
} from 'react-router-dom';
import { RouterToUrlQuery } from 'react-url-query';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import { appHistory } from './AppHistory';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Views
const Login = React.lazy(() => import('./views/Login/Login'));
const ForgotPassword = React.lazy(() => import('./views/Login/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/Login/ResetPassword'));

// 🙄
const toastContainerStyles = { zIndex: 1999 };

class App extends Component {
  render() {
    return (
      <>
        <Router history={appHistory}>
          <RouterToUrlQuery routerContext={RouterContext}>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/forgotPassword"
                  name="Forgot password"
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  exact
                  path="/resetPassword"
                  name="ResetPassword"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <DefaultLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </RouterToUrlQuery>
        </Router>
        <ToastContainer
          position="bottom-right"
          autoClose={15000}
          style={toastContainerStyles}
        />
      </>
    );
  }
}

export default App;
